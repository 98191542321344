import React from "react";
import { createRoot } from "react-dom/client";
import {
  BrowserRouter,
  useRoutes,
  useLocation,
  redirect,
} from "react-router-dom";
import AppRoute from "./config/app-route.jsx";
import { slideToggle } from "./composables/slideToggle.js";
import { useCookies } from "react-cookie";

// bootstrap
import "bootstrap";

// css
import "bootstrap-icons/font/bootstrap-icons.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./index.css";
import "./scss/styles.scss";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { useEffect } from "react";

const container = document.getElementById("root");
const root = createRoot(container);
function App() {
  // console.log(`call index.js`);
  let element = useRoutes(AppRoute);
  let location = useLocation();

  // on every route change
  React.useEffect(() => {
    const elm = document.querySelector(".app");
    if (elm) {
      elm.classList.remove("app-sidebar-mobile-toggled");
    }
    const elm2 = document.querySelector(".app-top-nav");
    if (elm2 && elm2.style.display === "block") {
      slideToggle(document.querySelector(".app-top-nav"));
    }
  }, [location]);
  const [cookie] = useCookies(["token"]);
  let tokenExists = !!cookie.token;
  if (process.env.NODE_ENV !== "production") tokenExists = true;
  useEffect(() => {
    if (!tokenExists) {
      // Replace the following URL with your external URL
      const externalURL = process.env.REACT_APP_HOMEPAGE;
      window.location.href = externalURL;
    }
  }, [tokenExists]);

  // return element;
  return tokenExists ? element : null;
}

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
);
