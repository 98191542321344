import React, { useEffect, useState, useRef } from "react";
import { Card, CardBody } from "./../../components/card/card.jsx";
import {
  useCodeCreate,
  useCodeRemove,
  useCodeList,
  useCodeUpdate,
} from "../../query/code.js";
import { toast } from "react-toastify";
import copy from "copy-to-clipboard";

function CodeManager() {
  const { data: codeList, refetch } = useCodeList();
  const { mutate: codeCreate } = useCodeCreate();
  const [code, setCode] = useState("");
  const [label, setLabel] = useState("");
  const { mutate: codeRemove } = useCodeRemove();
  const [codeIdx, setCodeIdx] = useState("");
  const [delCodeIdx, setDelCodeIdx] = useState("");
  const { mutate: codeUpdate } = useCodeUpdate();
  const modalCloseRef = useRef();

  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    refetch();
  }, []);
  return (
    <div className={"col-xl-9"}>
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item active">Affiliate code manager</li>
          </ul>
          <h1 className="page-header mb-0">
            <small>you can create up to 30 affiliate codes.</small>
          </h1>
        </div>
      </div>

      <hr className="mb-4" />

      <div id="basicTable" className="mb-5">
        <h4>Code List</h4>
        <Card>
          <CardBody>
            <div className="row mb-n3">
              <div className="col-xl-12">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Code</th>
                      <th scope="col">Link</th>
                      <th scope="col">Label</th>
                      <th scope="col">Create Date(UTC 0)</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {codeList?.result?.map((item, index) => {
                      return (
                        <tr key={item.idx}>
                          <th scope="row">{index + 1}</th>

                          <td>{item.referral_code}</td>
                          <td>
                            <a
                              href="#"
                              onClick={() => {
                                copy(
                                  `${process.env.REACT_APP_HOMEPAGE}af/${item.referral_code}`,
                                );
                                toast("Copy to Clipboard");
                              }}
                            >
                              {process.env.REACT_APP_HOMEPAGE}af/
                              {item.referral_code}
                              <i className="fas fa-lg fa-fw me-2 fa-copy"></i>{" "}
                            </a>
                          </td>
                          <td>{item.label}</td>
                          <td>{item.create_date}</td>
                          <td>
                            <div className={"row"}></div>
                            <button
                              type="button"
                              className="btn btn-outline-theme btn-sm me-2"
                              onClick={() => {
                                setCode(item.referral_code);
                                setLabel(item.label);
                                setCodeIdx(item.idx);
                              }}
                            >
                              Edit
                            </button>

                            <button
                              type="button"
                              className="btn btn-outline-danger btn-sm"
                              data-bs-toggle="modal"
                              data-bs-target="#modifyModal"
                              onClick={() => setDelCodeIdx(item.idx)}
                            >
                              Remove
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>

      <div id="helpText" className="mb-5">
        <h4>Create Affiliate Code</h4>
        <Card>
          <CardBody>
            <div className="mb-3 row">
              <label htmlFor="code" className="form-label">
                Code(*)
              </label>
              <input
                type="text"
                id="code"
                value={code}
                disabled={codeIdx ? true : false}
                onChange={e => setCode(e.target.value)}
                className="form-control mb-2"
                aria-describedby="passwordHelpBlock"
              />
              <small id="passwordHelpBlock" className="form-text text-muted">
                # 4 to 20 alphanumeric digits
              </small>
            </div>
            <div className="mb-3 row">
              <label htmlFor="label" className="form-label">
                Label(Optional)
              </label>
              <input
                type="text"
                id="label"
                value={label}
                onChange={e => setLabel(e.target.value)}
                className="form-control mb-2"
                aria-describedby="passwordHelpBlock"
              />
            </div>
            <div className="mb-3 row">
              <div className="col-sm-10">
                {codeIdx && (
                  <button
                    type="button"
                    className="btn btn-danger me-2"
                    onClick={() => {
                      setCodeIdx("");
                      setCode("");
                      setLabel("");
                    }}
                  >
                    Cancel
                  </button>
                )}

                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    if (isSubmit) return;
                    if (code === "") {
                      toast("Input code");
                      return;
                    }

                    setIsSubmit(true);

                    if (codeIdx) {
                      // modify
                      codeUpdate(
                        { idx: codeIdx, label },
                        {
                          onSettled: ret => {
                            setIsSubmit(false);
                            setCode("");
                            setLabel("");
                            setCodeIdx("");
                            if (ret?.error) {
                              toast(ret.data.code);
                            }
                          },
                        },
                      );
                    } else {
                      codeCreate(
                        { codeName: code, label },
                        {
                          onSettled: ret => {
                            setIsSubmit(false);
                            setCode("");
                            setLabel("");
                            console.log("onSettled:", ret);
                            if (ret?.error) {
                              toast(ret.data.code);
                            }
                          },
                        },
                      );
                    }
                  }}
                >
                  {codeIdx ? "Modify" : "Create"}
                </button>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
      <div id="modifyModal" className="modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Warning</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <p>Are you sure you want to delete it?</p>
              <p>
                When a code is deleted, you will lose all rewards flowing to it.
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default"
                data-bs-dismiss="modal"
                ref={modalCloseRef}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-outline-danger"
                onClick={() => {
                  if (isSubmit) return;
                  setIsSubmit(true);
                  codeRemove(
                    { idx: delCodeIdx },
                    {
                      onSettled: ret => {
                        setIsSubmit(false);
                        setDelCodeIdx("");
                        if (ret?.error) {
                          toast(ret.data.code);
                        }
                        modalCloseRef.current.click();
                      },
                    },
                  );
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CodeManager;
