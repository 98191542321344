import { useMutation } from "@tanstack/react-query";
import { fetchServer } from "../utils/fetch";

export const useSendTip = () => {
  return useMutation({
    queryKey: ["sendTip"],
    mutationFn: ({ targetEmail, assetTypeCode, amount }) => {
      return fetchServer("post", "api/partner/tip", { targetEmail, assetTypeCode, amount });
    },

  });
};