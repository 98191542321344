export function getCookie(name) {
  const cookies = document ? document.cookie.split(";") : "";
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim(); // 앞뒤 공백 제거
    if (cookie.indexOf(name + "=") === 0) {
      return cookie.substring(name.length + 1, cookie.length);
    }
  }
  return null;
}
