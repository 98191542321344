import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { calcPageNation } from "../../utils/pagenation.js";

export function PageNation({
  page,
  limit,
  pagesToShow,
  totalCount,
  itemLength,
  searchField = "",
  searchKeyword = "",
}) {
  //   console.log(page, limit, pagesToShow, totalCount, itemLength);
  const [, setSearchParams] = useSearchParams();
  const [itemNo, setItemNo] = useState({ startNo: 0, endNo: 0 });
  const [pageNation, setPageNation] = useState({
    startPage: 0,
    endPage: 0,
    prePage: 0,
    nextPage: 0,
    pages: [],
  });

  useEffect(() => {
    const pn = calcPageNation({
      currentPage: parseInt(page),
      totalCount,
      itemsPerPage: limit,
      pagesToShow,
    });
    pn.currentPage = parseInt(page);
    pn.pages = [];
    console.log(pn);
    const startNo = (page - 1) * limit + 1;
    const endNo = startNo + itemLength - 1;
    setItemNo({ startNo, endNo });

    for (let i = pn.startPage; i <= pn.endPage; i++) pn.pages.push(i);
    setPageNation(pn);
  }, [
    page,
    limit,
    pagesToShow,
    totalCount,
    itemLength,
    searchField,
    searchKeyword,
  ]);

  return (
    <div className="d-md-flex align-items-center">
      <div className="me-md-auto text-md-left text-center mb-2 mb-md-0">
        Showing {itemNo?.startNo} to {itemNo?.endNo} of {totalCount} entries
      </div>
      <ul className="pagination mb-0 justify-content-center">
        <li
          className={
            pageNation?.prePage > 0 ? "page-item" : "page-item disabled"
          }
        >
          <a
            className="page-link"
            href="#"
            onClick={() => {
              if (pageNation?.prePage === 0) return;
              setSearchParams({
                page: pageNation?.prePage,
                searchField,
                searchKeyword,
              });
            }}
          >
            Previous
          </a>
        </li>
        {pageNation?.pages.map(item => {
          return (
            <li className="page-item" key={item}>
              <a
                className={
                  item === pageNation?.currentPage
                    ? "page-link active"
                    : "page-link"
                }
                href="#"
                onClick={() => {
                  if (item === pageNation?.currentPage) return;
                  console.log(`click`, item);
                  setSearchParams({ page: item, searchField, searchKeyword });
                }}
              >
                {item}
              </a>
            </li>
          );
        })}

        <li
          className={
            pageNation?.nextPage > 0 ? "page-item" : "page-item disabled"
          }
        >
          <a
            className="page-link"
            href="#"
            onClick={() => {
              if (pageNation?.nextPage === 0) return;
              setSearchParams({
                page: pageNation?.nextPage,
                searchField,
                searchKeyword,
              });
            }}
          >
            Next
          </a>
        </li>
      </ul>
    </div>
  );
}
