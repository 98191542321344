import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchServer } from "../utils/fetch";
export const useCodeList = () => {
  return useQuery({
    queryKey: ["codeList"],
    queryFn: () => {
      return fetchServer("get", "api/partner/code", null);
    },
  });
};

export const useCodeCreate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    queryKey: ["codeCreate"],
    mutationFn: ({ codeName, label }) => {
      if (label === "") label = null;
      return fetchServer("post", "api/partner/code", { codeName, label });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["codeList"]);
    },
  });
};

export const useCodeRemove = () => {
  const queryClient = useQueryClient();
  return useMutation({
    queryKey: ["codeRemove"],
    mutationFn: ({ idx }) => {
      console.log(`delete idx: ${idx}`);
      return fetchServer("delete", "api/partner/code", { idx: idx.toString() });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["codeList"]);
    },
  });
};

export const useCodeUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    queryKey: ["codeUpdate"],
    mutationFn: ({ idx, label }) => {
      console.log({ idx, label });
      return fetchServer("put", "api/partner/code", {
        idx: idx.toString(),
        label,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["codeList"]);
    },
  });
};
