import React, { useCallback, useEffect, useState } from "react";
import Chart from "chart.js/auto";
import ReactApexChart from "react-apexcharts";
import Highlight from "react-highlight";
import jsVectorMap from "jsvectormap";
import "jsvectormap/dist/maps/world.js";
import "jsvectormap/dist/css/jsvectormap.min.css";
import Moment from "moment";
import BigNumber from "bignumber.js";
import { useCookies } from "react-cookie";
import {
  Card,
  CardBody,
  CardExpandToggler,
} from "./../../components/card/card.jsx";
import { useDashboard } from "../../query/dashboard.js";
function Dashboard() {
  const { data, refetch } = useDashboard();
  const [, , removeToken] = useCookies(["token"]);

  const [pieChartOptions, setPieChartOptions] = useState(null);
  const [curMonth, setCurMonth] = useState({
    cnt: "0",
    wagger: "0",
    rtp: "0",
    ggr: "0",
  });
  const [preMonth, setPreMonth] = useState({
    ratio: "0",
    ggr: "0",
    ngr: "0",
    wagger: "0",
    rtp: "0",
    bonus: "0",
    tex: "0",
  });

  let chart = "";

  useEffect(() => {
    console.log("data", data);
    if (process.env.NODE_ENV === "production" && data?.status === 401) {
      removeToken("token", {
        path: "/",
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
      });
      window.location.href = process.env.REACT_APP_HOMEPAGE;
    }
    if (!data?.result) return;
    console.log(data);
    const currentMonth = data.result.currentMonth;

    if (currentMonth) {
      const wagger = new BigNumber(currentMonth.wagger);
      const rtp = new BigNumber(currentMonth.rtp);
      const ggr = wagger.minus(rtp);

      setCurMonth({
        cnt: currentMonth.cnt,
        wagger: wagger.toFormat(2),
        rtp: rtp.toFormat(2),
        ggr: ggr.toFormat(2),
      });
    }

    const previousMonth = data.result.preMonth;
    if (previousMonth) {
      const wagger = new BigNumber(previousMonth.wagger);
      const rtp = new BigNumber(previousMonth.rtp);
      const ggr = wagger.minus(rtp);
      const bonus = wagger.times(0.0045);
      const tex = wagger.times(0.005);
      const ngr = ggr.minus(bonus).minus(tex);
      const ratio = new BigNumber(previousMonth.ratio).times(100);
      setPreMonth({
        feeRatio: ratio.toFormat(2),
        wagger: wagger.toFormat(2),
        rtp: rtp.toFormat(2),
        ggr: ggr.toFormat(2),
        bonus: bonus.toFormat(2),
        tex: tex.toFormat(2),
        ngr: ngr.toFormat(2),
      });
    }
    const dailyStatistics = data.result.dailyStatistics;
    if (dailyStatistics) {
      const dayList = dailyStatistics.map(item =>
        item.date.substring(item.date.lastIndexOf("-") + 1),
      );
      const waggerList = dailyStatistics.map(item =>
        new BigNumber(item.wagger).dp(2).toNumber(),
      );
      const rtpList = dailyStatistics.map(item =>
        new BigNumber(item.rtp).dp(2).toNumber(),
      );
      console.log(dayList, waggerList, rtpList);
      renderChart(dayList, waggerList, rtpList);
    }

    const afCodeRankList = data.result.afCodeRankList;
    if (afCodeRankList) {
      const labels = afCodeRankList.map(item => item.input_code);
      const series = afCodeRankList.map(item => item.cnt);
      setPieChartOptions(getPieChartOptions(labels, series));
    }
  }, [data]);

  const renderChart = useCallback((days, waggerList, rtpList) => {
    // color & font variable
    let gray300Color = getComputedStyle(document.body)
      .getPropertyValue("--bs-gray-300")
      .trim();
    let gray300RgbColor = getComputedStyle(document.body)
      .getPropertyValue("--bs-gray-300-rgb")
      .trim();
    let indigoColor = getComputedStyle(document.body)
      .getPropertyValue("--bs-indigo")
      .trim();
    let bodyColor = getComputedStyle(document.body)
      .getPropertyValue("--bs-body-color")
      .trim();
    let bodyBg = getComputedStyle(document.body)
      .getPropertyValue("--bs-body-bg")
      .trim();
    let borderColor = getComputedStyle(document.body)
      .getPropertyValue("--bs-border-color")
      .trim();
    let bodyFontFamily = getComputedStyle(document.body)
      .getPropertyValue("--bs-body-font-family")
      .trim();
    let bodyFontWeight = getComputedStyle(document.body)
      .getPropertyValue("--bs-body-font-weight")
      .trim();
    let inverse = getComputedStyle(document.body)
      .getPropertyValue("--bs-inverse")
      .trim();
    let themeColor = getComputedStyle(document.body)
      .getPropertyValue("--bs-theme")
      .trim();

    // chart global options
    Chart.defaults.font.family = bodyFontFamily;
    Chart.defaults.font.size = 12;
    Chart.defaults.color = bodyColor;
    Chart.defaults.borderColor = borderColor;
    Chart.defaults.plugins.legend.display = false;
    Chart.defaults.plugins.tooltip.padding = {
      left: 8,
      right: 12,
      top: 8,
      bottom: 8,
    };
    Chart.defaults.plugins.tooltip.cornerRadius = 8;
    Chart.defaults.plugins.tooltip.titleMarginBottom = 6;
    Chart.defaults.plugins.tooltip.color = inverse;
    Chart.defaults.plugins.tooltip.multiKeyBackground = inverse;
    Chart.defaults.plugins.tooltip.backgroundColor = bodyBg;
    Chart.defaults.plugins.tooltip.titleFont.family = bodyFontFamily;
    Chart.defaults.plugins.tooltip.titleFont.weight = bodyFontWeight;
    Chart.defaults.plugins.tooltip.titleColor = inverse;
    Chart.defaults.plugins.tooltip.footerFont.family = bodyFontFamily;
    Chart.defaults.plugins.tooltip.displayColors = true;
    Chart.defaults.plugins.tooltip.boxPadding = 6;
    Chart.defaults.scale.grid.color = borderColor;
    Chart.defaults.scale.beginAtZero = true;
    Chart.defaults.maintainAspectRatio = false;

    // #chart
    let chartContainer = document.getElementById("chart-4");
    if (chart) {
      chart.destroy();
    }
    if (chartContainer) {
      chartContainer.innerHTML =
        '<canvas id="chart" className="w-100" height="190"></canvas>';
      chart = new Chart(document.getElementById("chart").getContext("2d"), {
        type: "line",
        data: {
          labels: days,
          datasets: [
            {
              color: themeColor,
              backgroundColor: "transparent",
              borderColor: themeColor,
              borderWidth: 2,
              pointBackgroundColor: bodyBg,
              pointBorderWidth: 2,
              pointRadius: 4,
              pointHoverBackgroundColor: bodyBg,
              pointHoverBorderColor: themeColor,
              pointHoverRadius: 6,
              pointHoverBorderWidth: 2,
              data: rtpList,
            },
            {
              color: gray300Color,
              backgroundColor: "rgba(" + gray300RgbColor + ", .2)",
              borderColor: gray300Color,
              borderWidth: 2,
              pointBackgroundColor: bodyBg,
              pointBorderWidth: 2,
              pointRadius: 4,
              pointHoverBackgroundColor: bodyBg,
              pointHoverBorderColor: gray300Color,
              pointHoverRadius: 6,
              pointHoverBorderWidth: 2,
              data: waggerList,
            },
          ],
        },
      });
    }
  }, []);

  const getPieChartOptions = useCallback((labels, series) => {
    console.log(`labels`, labels);
    console.log(`series`, series);
    let themeColorRgb = getComputedStyle(document.body)
      .getPropertyValue("--bs-theme-rgb")
      .trim();
    let themeFont = getComputedStyle(document.body)
      .getPropertyValue("--bs-body-font-family")
      .trim();
    let indigoRgb = getComputedStyle(document.body)
      .getPropertyValue("--bs-indigo-rgb")
      .trim();
    let pinkRgb = getComputedStyle(document.body)
      .getPropertyValue("--bs-pink-rgb")
      .trim();
    let orangeRgb = getComputedStyle(document.body)
      .getPropertyValue("--bs-warning-rgb")
      .trim();
    let inverse = getComputedStyle(document.body)
      .getPropertyValue("--bs-inverse")
      .trim();
    let inverseRgb = getComputedStyle(document.body)
      .getPropertyValue("--bs-inverse-rgb")
      .trim();
    return {
      option: {
        dataLabels: {
          dropShadow: { enabled: false, top: 1, left: 1, blur: 1, opacity: 1 },
        },
        stroke: { show: false },
        // colors: [
        //   "rgba(" + pinkRgb + ", .75)",
        //   "rgba(" + orangeRgb + ", .75)",
        //   "rgba(" + themeColorRgb + ", .75)",
        //   "rgba(" + inverseRgb + ", .5)",
        //   "rgba(" + indigoRgb + ", .75)",
        // ],
        labels: labels,

        legend: { fontFamily: themeFont, labels: { colors: inverse } },
        // legend: { fontFamily: themeFont, },
        title: {
          text: "",
          style: {
            fontSize: "14px",
            fontWeight: "bold",
            fontFamily: themeFont,
            color: inverse,
          },
        },
      },
      series: series,
    };
  }, []);
  useEffect(() => {
    refetch();
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-xl-3 col-lg-6">
          <Card className="mb-3">
            <CardBody>
              <div className="d-flex fw-bold small mb-3">
                <span className="flex-grow-1">
                  This month's Gross Gaming Revenue
                </span>
              </div>
              <div className="row align-items-center mb-2">
                <div className="col-7">
                  <h3 className="mb-0">$ {curMonth.ggr}</h3>
                </div>
              </div>
              <div className="small text-inverse text-opacity-50 text-truncate">
                <div>
                  <i className={"far fa-user fa-fw me-1"}></i> players{" "}
                  {curMonth.cnt}
                </div>
                <div>
                  <i className={"fas fa-arrow-right"}></i> Bet Amount $
                  {curMonth.wagger}
                </div>

                <div>
                  <i className={"fas fa-arrow-left"}></i> Return to player $
                  {curMonth.rtp}
                </div>
              </div>
            </CardBody>
          </Card>
        </div>

        <div className="col-xl-3 col-lg-6">
          <Card className="mb-3">
            <CardBody>
              <div className="d-flex fw-bold small mb-3">
                <span className="flex-grow-1">
                  Previous month's settlement fee
                </span>
              </div>
              <div className="row align-items-center mb-2">
                <div className="col-7">
                  <h3 className="mb-0">$ {preMonth.ngr}</h3>
                </div>
              </div>
              <div className="small text-inverse text-opacity-50 text-truncate">
                <div>
                  <i className={"fas fa-calculator"}></i> Fee Ratio{" "}
                  {preMonth.ratio}%
                </div>
                <div>
                  <i className={"fas fa-smile"}></i> GGR ${preMonth.ggr}
                </div>
                <div>
                  <i className={"fas fa-arrow-right"}></i> Bet Amount $
                  {preMonth.wagger}
                </div>

                <div>
                  <i className={"fas fa-arrow-left"}></i> Return to player $
                  {preMonth.rtp}
                </div>
              </div>
            </CardBody>
          </Card>
        </div>

        <div className="col-xl-3 col-lg-6">
          <Card className="mb-3">
            <CardBody>
              <div className="d-flex fw-bold small mb-3">
                <span className="flex-grow-1">Top affiliate codes</span>
              </div>
              <div className="row align-items-center mb-2">
                <div className="col-9">
                  <h3 className="mb-0">
                    {data?.result?.afCodeRankList[0]?.input_code ?? "-"}
                  </h3>
                </div>
              </div>
              <div className="small text-inverse text-opacity-50 text-truncate">
                {data?.result?.afCodeRankList.slice(0, 4).map((item, index) => {
                  return (
                    <div key={index}>
                      {index + 1} <i className={"fas fa-angle-right"}></i>{" "}
                      Invite {item.cnt} with '{item.input_code}'
                    </div>
                  );
                })}
              </div>
            </CardBody>
          </Card>
        </div>

        <div className="row">
          <div className="col-xl-3 col-lg-6">
            <Card className="mb-3">
              <CardBody>
                <div className="d-flex align-items-center mb-2">
                  <div className="flex-fill fw-bold fs-16px">
                    This month's Data
                  </div>
                </div>
                <div>
                  <div className="fs-12px fw-bold mb-2 text-inverse text-opacity-50">
                    $ (USD)
                  </div>
                  <div className="chart mb-2" style={{ height: "190px" }}>
                    <div id="chart-4"></div>
                  </div>
                  <div className="d-flex align-items-center justify-content-center fw-bold text-inverse text-opacity-50">
                    <i className="fa fa-square text-gray-300 me-2"></i>
                    <span className="fs-12px me-4">Wager</span>
                    <i className="fa fa-square text-theme me-2"></i>
                    <span className="fs-12px me-4">RTP</span>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>

          <div className="col-xl-3 col-lg-6" id="apexChartPieChart">
            <Card className="mb-3">
              <CardBody>
                <h6>Affiliate acquisition statistics</h6>
                {pieChartOptions && (
                  <ReactApexChart
                    type="pie"
                    options={pieChartOptions.option}
                    series={pieChartOptions.series}
                  />
                )}
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
