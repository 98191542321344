import { useQuery } from "@tanstack/react-query";
import { fetchServer } from "../utils/fetch";
export const useUserProfile = () => {
  return useQuery({
    queryKey: ["userProfile"],
    queryFn: () => {
      return fetchServer("get", "api/user/profile", null);
    },
    onSettled: r => {
      console.log(r);
    },
  });
};
