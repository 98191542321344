import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchServer } from "../utils/fetch";
export const useSettlement = page => {
  return useQuery({
    queryKey: ["settlement", page],
    queryFn: () => {
      return fetchServer("get", "api/partner/settlement", { page });
    },
  });
};

export const useSettlementDetail = idx => {
  return useQuery({
    queryKey: ["settlementDetail", idx],
    queryFn: () => {
      return fetchServer("get", "api/partner/settlement-detail", {
        idx,
      });
    },
  });
};

export const usePaidOut = () => {
  const queryClient = useQueryClient();
  return useMutation({
    queryKey: ["paidOut"],
    mutationFn: ({ settlementIdx }) => {
      console.log(`settlementIdx: ${settlementIdx}`);
      return fetchServer("post", "api/partner/paid-out", {
        settlementIdx: parseInt(settlementIdx),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["settlement"]);
    },
  });
};
