import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useIsFetching, useIsMutating } from "@tanstack/react-query";
import ClipLoader from "react-spinners/ClipLoader";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function Content(props) {
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();

  useEffect(() => {
    console.log(`isFetching: ${isFetching}`);
  }, [isFetching]);

  useEffect(() => {
    console.log(`isMutating: ${isMutating}`);
  }, [isMutating]);
  return (
    <div
      className={
        "position-relative app-content " +
        (props.className ? props.className : "")
      }
    >
      <Outlet />

      <div
        className={"position-absolute"}
        style={{ top: "50%", left: "35%", transform: "translateX(-50%)" }}
      >
        <ClipLoader
          color={"#ffffff"}
          loading={isFetching === 1}
          cssOverride={override}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    </div>
  );
}

export default Content;
