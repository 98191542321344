import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody } from "../../components/card/card.jsx";
import { useUserStatistics } from "../../query/userStatistics.js";
import { useSearchParams } from "react-router-dom";
import { PageNation } from "../../components/utils/pagenation.jsx";
import BigNumber from "bignumber.js";
import { useSendTip } from "../../query/tip";
import { toast } from "react-toastify";
function User() {
  const [searchParams, setSearchParams] = useSearchParams({
    page: "1",
    searchKeyword: "",
  });

  const currentPage = searchParams.get("page");
  const searchKeyword = searchParams.get("searchKeyword");
  const [searchStr, setSearchStr] = useState(searchKeyword);
  const [tipEmail, setTipEMail] = useState('');
  const [isSubmit, setIsSubmit] = useState(false);
  const [assetTypeCode, setAssetTypeCode] = useState("USDT");
  const [amount, setAmount] = useState("");
  const {mutate: sendTip} = useSendTip();
  const modalCloseRef = useRef(null);

  const { data, refetch } = useUserStatistics({
    page: currentPage,
    searchKeyword,
  });
  const [pageNation, setPageNation] = useState({
    page: currentPage,
    searchKeyword,
    pagesToShow: 6,
  });
  useEffect(() => {
    if (!data?.result) return;
    console.log(data);
    const { totalCount, limit, list } = data.result;
    setPageNation(draft => {
      return {
        ...draft,
        page: currentPage,
        searchKeyword,
        limit,
        totalCount,
        itemLength: list.length,
      };
    });
  }, [data]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    console.log(assetTypeCode, amount);
  }, [amount, assetTypeCode]);

  return (
    <div className={"col-xl-9"}>
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item active">Statistics</li>
          </ul>
          <h1 className="page-header mb-0">Your referrals list</h1>
        </div>
      </div>

      <Card>
        <div className="tab-content  p-4">
          <div className="input-group mb-4">
            <div className="flex-fill position-relative xl-2">
              <div className="input-group">
                <div
                  className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                  style={{ zIndex: 1020 }}
                >
                  <i className="fa fa-search opacity-5"></i>
                </div>
                <input
                  type="search"
                  className="form-control ps-35px"
                  placeholder="Email Or Nick name"
                  maxLength={60}
                  value={searchStr}
                  onChange={e => setSearchStr(e.target.value)}
                  onKeyUp={e => {
                    if (e.key === "Enter") {
                      setSearchParams({
                        page: "1",
                        searchKeyword: e.target.value,
                      });
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-hover text-nowrap">
              <thead>
              <tr>
                <th></th>
                <th className="border-top-0 pt-0 pb-2">email</th>
                <th className="border-top-0 pt-0 pb-2">Reg Date</th>
                <th className="border-top-0 pt-0 pb-2">Affiliate Code</th>
                <th className="border-top-0 pt-0 pb-2">nick name</th>
                <th className="border-top-0 pt-0 pb-2">Deposit</th>
                <th className="border-top-0 pt-0 pb-2">Wager</th>
                <th className="border-top-0 pt-0 pb-2">RTP</th>
                <th className="border-top-0 pt-0 pb-2">GGR</th>
                <th className="border-top-0 pt-0 pb-2">Tip</th>
              </tr>
              </thead>
              <tbody>
              {data?.result?.list?.map((item, index) => {
                return (
                  <tr key={item.idx}>
                    <th>
                      {pageNation?.itemLength &&
                        pageNation.totalCount -
                        ((pageNation.page - 1) * pageNation.limit + index)}
                    </th>
                    <td className="align-middle">
                      {item.email}
                    </td>
                    <td className="align-middle">{item.date}</td>
                    <td className="align-middle">{item.input_code}</td>

                    <td className="align-middle">{item.nickname}</td>
                    <td className="align-middle">${new BigNumber(item.deposit_dollar).toFormat(2)}</td>
                    <td className="align-middle">
                      ${new BigNumber(item.wagger).toFormat(2)}
                    </td>
                    <td className="align-middle">
                      ${new BigNumber(item.rtp).toFormat(2)}
                    </td>
                    <td className="align-middle">
                      $
                      {new BigNumber(item.wagger).minus(item.rtp).toFormat(2)}
                    </td>
                    <td>
                      <a href="#modalTips" onClick={() => setTipEMail(item.email)} data-bs-toggle="modal"
                         className="btn btn-indigo mb-1 me-1">
                        Send tips
                      </a>
                    </td>
                  </tr>
                );
              })}
              </tbody>
            </table>
          </div>
          {pageNation?.itemLength && (
            <PageNation
              page={pageNation.page}
              limit={pageNation.limit}
              pagesToShow={pageNation.pagesToShow}
              totalCount={pageNation.totalCount}
              itemLength={pageNation.itemLength}
              searchKeyword={pageNation.searchKeyword}
            />
          )}
        </div>
      </Card>

      <div className="modal fade" id="modalTips">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Send tips</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label className="form-label">To</label>
                <div className="row row-space-10">
                  <div className="col-4">
                    {tipEmail}
                  </div>
                </div>
              </div>
              <div className="alert bg-inverse bg-opacity-15">
                <b>Please note:</b>
                Send the selected assets to the user.
                Your balance will be deducted.
              </div>
              <div className="mb-3">
                <label className="form-label">Asset</label>
                <div>
                  <div className="row row-space-10">
                    <div className="col-4">
                      <select className="form-select" onChange={(event)=>{
                        setAssetTypeCode(event.target.value);
                      }}>
                        <option value={"USDT"}>USDT</option>
                        <option value={"USDC"}>USDC</option>
                        <option value={"BTC"}>BTC</option>
                        <option value={"ETH"}>ETH</option>
                        <option value={"BNB"}>BNB</option>
                        <option value={"XRP"}>XRP</option>
                        <option value={"TRX"}>TRX</option>
                        <option value={"SOL"}>SOL</option>
                      </select>

                    </div>
                    <div className="col-4">
                    <input className="form-control" placeholder="Amount" value={amount} onChange={(event) => {
                      setAmount(event.target.value);
                    }}/>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" ref={modalCloseRef} className="btn btn-outline-default" data-bs-dismiss="modal">Close</button>
              <button type="button" className="btn btn-outline-theme" onClick={() => {
                if(isSubmit) return;

                setIsSubmit(true);
                sendTip({targetEmail: tipEmail, assetTypeCode, amount }, {onSettled: (ret) => {
                  setIsSubmit(false);
                  modalCloseRef.current.click();
                  setAmount('');


                  if(ret.result) {
                    toast('Success')
                  } else {
                    switch (ret.code) {
                      case '-10101':
                        toast('The user was not found. ')
                        break;
                      case '-10100':
                        toast('Insufficient balance. ')
                        break;
                    }

                  }

                }});
              }}>Submit</button>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default User;
