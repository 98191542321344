import React, { useCallback, useEffect, useState } from "react";
import { Card, CardBody } from "../../components/card/card.jsx";
import { useSettlement } from "../../query/settlement.js";
import BigNumber from "bignumber.js";
import { useSearchParams, Link } from "react-router-dom";
import { PageNation } from "../../components/utils/pagenation.jsx";

function Settlement() {
  const [searchParams, setSearchParams] = useSearchParams({ page: "1" });
  const currentPage = searchParams.get("page");

  const { data, refetch } = useSettlement(currentPage);
  const [summary, setSummary] = useState(null);
  // const { mutate: paidOut } = usePaidOut();
  const [pageNation, setPageNation] = useState(null);

  useEffect(() => {
    console.log(data);
    if (!data?.result) return;
    const { totalCount, page, limit, list } = data.result;
    setPageNation({
      page,
      limit,
      pagesToShow: 6,
      totalCount,
      itemLength: list.length,
    });

    setSummary({
      wagger: new BigNumber(data.result.summary.wagger).toFormat(2),
      rtp: new BigNumber(data.result.summary.rtp).toFormat(2),
      paidOut: new BigNumber(data.result.summary.paid_out).toFormat(2),
    });
  }, [data]);

  // useEffect(() => {
  //   refetch();
  // }, []);

  return (
    <div className="col-xl-9">
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item active">Settlement</li>
          </ul>
          <h1 className="page-header mb-0">Settlement</h1>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-4 col-lg-6">
          <Card className="mb-3">
            <CardBody>
              <div className="d-flex fw-bold small mb-3">
                <span className="flex-grow-1">PAYOUT AMOUNT</span>
              </div>
              <div className="row align-items-center mb-2">
                <div className="col-7">
                  <h3 className="mb-0">${summary?.paidOut}</h3>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="col-xl-4 col-lg-6">
          <Card className="mb-3">
            <CardBody>
              <div className="d-flex fw-bold small mb-3">
                <span className="flex-grow-1">TOTAL WAGER</span>
              </div>
              <div className="row align-items-center mb-2">
                <div className="col-7">
                  <h3 className="mb-0">${summary?.wagger}</h3>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="col-xl-4 col-lg-6">
          <Card className="mb-3">
            <CardBody>
              <div className="d-flex fw-bold small mb-3">
                <span className="flex-grow-1">TOTAL RTP</span>
              </div>
              <div className="row align-items-center mb-2">
                <div className="col-7">
                  <h3 className="mb-0">${summary?.rtp}</h3>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>

      <Card>
        <div className="tab-content  p-4">
          <div className="table-responsive">
            <table className="table table-hover text-nowrap">
              <thead>
                <tr>
                  <th className="border-top-0 pt-0 pb-2">Date</th>
                  <th className="border-top-0 pt-0 pb-2">Wager</th>
                  <th className="border-top-0 pt-0 pb-2">RTP</th>
                  <th className="border-top-0 pt-0 pb-2">GGR</th>
                  <th className="border-top-0 pt-0 pb-2">bonus</th>
                  <th className="border-top-0 pt-0 pb-2">Tax</th>
                  <th className="border-top-0 pt-0 pb-2">NGR</th>
                  <th className="border-top-0 pt-0 pb-2">Sharing Rate(%)</th>
                  <th className="border-top-0 pt-0 pb-2">Settlement</th>
                </tr>
              </thead>
              <tbody>
                {data?.result?.list?.map(item => {
                  return (
                    <tr key={item.idx}>
                      <td className="align-middle">
                        {item.idx === 0 && item.date}
                        {item.idx > 0 && (
                          <Link to={`/settlement-detail?id=${item.idx}`}>
                            {item.date}
                          </Link>
                        )}
                      </td>
                      <td className="align-middle">
                        ${new BigNumber(item.wagger).toFormat(2)}
                      </td>
                      <td className="align-middle">
                        ${new BigNumber(item.rtp).toFormat(2)}
                      </td>
                      <td className="align-middle">
                        ${new BigNumber(item.ggr).toFormat(2)}
                      </td>
                      <td className="align-middle">
                        ${new BigNumber(item.wagger).times(0.0045).toFormat(2)}
                      </td>
                      <td className="align-middle">
                        ${new BigNumber(item.wagger).times(0.005).toFormat(2)}
                      </td>
                      <td className="align-middle">
                        ${new BigNumber(item.ngr).toFormat(2)}
                      </td>
                      <td className="align-middle">
                        {item.ratio === "0"
                          ? "-"
                          : new BigNumber(item.ratio).times(100).toFormat(0) +
                            "%"}
                      </td>

                      <td className="align-middle">
                        {item.complete_yn === "P" && (
                          <span className="badge border border-warning text-warning px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                            <i className="fa fa-circle fs-9px fa-fw me-5px"></i>{" "}
                            In Progress
                          </span>
                        )}

                        {item.complete_yn === "Y" && (
                          <span className="badge border border-gray-300 text-gray-300 px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                            <i className="fa fa-circle fs-9px fa-fw me-5px"></i>{" "}
                            Completed
                          </span>
                        )}

                        {item.complete_yn === "N" && (
                          <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                            <i className="fa fa-circle fs-9px fa-fw me-5px"></i>{" "}
                            Available
                          </span>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {pageNation?.itemLength && (
            <PageNation
              page={pageNation.page}
              limit={pageNation.limit}
              pagesToShow={pageNation.pagesToShow}
              totalCount={pageNation.totalCount}
              itemLength={pageNation.itemLength}
            />
          )}
        </div>
      </Card>
    </div>
  );
}

export default Settlement;
