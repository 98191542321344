import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from "./../../components/card/card.jsx";
import { useSettlementDetail, usePaidOut } from "../../query/settlement.js";
import { Link, useSearchParams } from "react-router-dom";
import BigNumber from "bignumber.js";
import { toast } from "react-toastify";

function SettlementDetail() {
  const [param] = useSearchParams();
  const idx = param.get("id");
  const { data } = useSettlementDetail(idx);
  const [detail, setDetail] = useState(null);
  const { mutate: paidOut } = usePaidOut();

  useEffect(() => {
    console.log(data);
    if (!data?.result) return;
    const d = data.result.list[0];
    const providerFee = new BigNumber(d.ggr).times(0.1);
    setDetail({
      date: d.date,
      ratio: d.ratio * 100,
      rtp: new BigNumber(d.rtp).toFormat(2),
      wagger: new BigNumber(d.wagger).toFormat(2),
      ggr: new BigNumber(d.ggr).toFormat(2),
      ngr: new BigNumber(d.ngr).toFormat(2),
      paidOut: new BigNumber(d.paid_out).toFormat(2),
      bonus: new BigNumber(d.wagger).times(d.bonus_ratio ?? 0.0045).plus(providerFee).toFormat(2), // 프로바이더 수수료를 보너스에 녹이자. ggr 10%추가 공제 (보여주기용)
      tax: new BigNumber(d.wagger).times(0.0005).toFormat(2),
      completeYN: d.complete_yn,
    });
  }, [data]);
  return (
    <div>
      <div className="d-flex align-items-center mb-3">
        <div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/settlement">Settlement</Link>
            </li>
            <li className="breadcrumb-item active">DETAILS</li>
          </ol>
          <h1 className="page-header mb-0">Details</h1>
        </div>
      </div>
      {/* https://slotegrator.pro/analytical_articles/online-casino-performance-indicators-main-difference-between-ggr-and-ngr.html */}
      <div className="row gx-4">
        <div className="col-lg-8">
          <Card>
            <CardHeader className="d-flex align-items-center bg-inverse bg-opacity-10 fw-400">
              Records
              <span className="ms-auto text-decoration-none text-inverse text-opacity-50">
                <i className="me-1 fa-lg"></i> {detail?.date}
              </span>
            </CardHeader>
            <CardBody>
              <table className="table table-borderless table-sm m-0">
                <tbody>
                  <tr>
                    <td className="w-150px">Wager(A)</td>
                    <td></td>
                    <td className="text-end">${detail?.wagger}</td>
                  </tr>
                  <tr>
                    <td className="w-150px">RTP(B)</td>
                    <td>Return to player</td>
                    <td className="text-end">${detail?.rtp}</td>
                  </tr>
                  <tr>
                    <td className="w-150px">GGR</td>
                    <td>Gross gaming revenue</td>
                    <td className="text-end">${detail?.ggr}</td>
                  </tr>
                  <tr>
                    <td>Bonus(C)</td>
                    <td>Deduction of the bonus payout <br />percentage of the deposit</td>
                    <td className="text-end">${detail?.bonus}</td>
                  </tr>
                  <tr>
                    <td>Tax(D)</td>
                    <td>Wager * 1% * 5%</td>
                    <td className="text-end">${detail?.tax}</td>
                  </tr>
                  <tr>
                    <td>NGR</td>
                    <td>Net gaming revenue = (A) - (B) - (C) - (D)</td>
                    <td className="text-end">${detail?.ngr}</td>
                  </tr>
                  <tr>
                    <td>Share Ratio(E)</td>
                    <td></td>
                    <td className="text-end">{detail?.ratio}%</td>
                  </tr>

                  <tr>
                    <td colSpan="3">
                      <hr className="m-0" />
                    </td>
                  </tr>
                  <tr>
                    <td className="w-150px pb-2">
                      <b>Paid Out</b>
                    </td>
                    <td>NGR * (E)</td>
                    <td className="text-end pb-2 text-decoration-underline">
                      <b>${detail?.paidOut}</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </CardBody>
            <CardFooter className="bg-none d-flex p-3">
              {detail?.completeYN === "N" && (
                <button
                  type="button"
                  className="btn btn-yellow ms-auto"
                  onClick={() => {
                    paidOut(
                      { settlementIdx: idx },
                      {
                        onSettled: ret => {
                          console.log(ret, ret.code !== 0);
                          if (ret?.error) {
                            toast(ret.data.code);
                          } else if (ret.code !== 0) {
                            toast(ret.message);
                          }
                        },
                      },
                    );
                  }}
                >
                  Settlement (${detail?.paidOut})
                </button>
              )}

              <Link
                to="/settlement"
                className="btn btn-outline-theme ms-auto me-1"
              >
                List
              </Link>
            </CardFooter>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default SettlementDetail;
