import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchServer } from "../utils/fetch";
export const useUserStatistics = ({ page, searchKeyword }) => {
  return useQuery({
    queryKey: ["userStatistics", page, searchKeyword],
    queryFn: () => {
      return fetchServer("get", "api/partner/user-statistics", {
        page,
        searchKeyword,
      });
    },
  });
};
