export const calcPageNation = ({
  currentPage,
  totalCount,
  itemsPerPage,
  pagesToShow,
}) => {
  // const itemsPerPage = 20; // 페이지당 보여질 아이템 수
  // const pagesToShow = 6; // 보여질 페이지 번호 수

  const totalPages = Math.ceil(totalCount / itemsPerPage);

  const pagePeriod = [1, totalPages];
  pagePeriod[1] = Math.ceil(currentPage / pagesToShow) * pagesToShow;
  pagePeriod[0] = pagePeriod[1] - (pagesToShow - 1);

  pagePeriod[0] = Math.max(pagePeriod[0], 1);
  pagePeriod[1] = Math.min(pagePeriod[1], totalPages);

  let prePage = Math.max(pagePeriod[0] - 1, 1);
  let nextPage = Math.min(pagePeriod[1] + 1, totalPages);

  if (currentPage === prePage) prePage = 0;
  if (currentPage === nextPage) nextPage = 0;

  console.log(pagePeriod[0], pagePeriod[1], prePage, nextPage);
  return {
    startPage: pagePeriod[0],
    endPage: pagePeriod[1],
    prePage,
    nextPage,
  };
};
