import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchServer } from "../utils/fetch";
export const useDashboard = () => {
  return useQuery({
    queryKey: ["dashboard"],
    queryFn: () => {
      console.log("run?!!");
      return fetchServer("get", "api/partner/dashboard", null);
    },
  });
};
