import { getCookie } from "./cookie";
import axios from 'axios';
import { toast } from "react-toastify";

export const fetchServer = async (method, url, data, headers) => {
  const token = getCookie("token");
  const options = {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
  if (headers) {
    options.headers = { ...headers, ...options.headers };
  }
  let runUrl;
  if (url.startsWith("http")) {
    runUrl = url;
  } else {
    runUrl = `${process.env.REACT_APP_API_ENDPOINT}${url}`;
  }
  console.log(options);
  console.log(runUrl);
  // const result = await fetchJson.request(method, runUrl, data, options);

  try {
    const result = await axios({ method, url: runUrl, data: method.toLowerCase() !== 'get' ? data : null,
      params: method.toLowerCase() === 'get' ? data : null, headers: options.headers })
    return result.data;
  } catch(err) {
    console.log(err.response.status);
    if(err.response.status === 401) {
      // window.location.href = process.env.REACT_APP_HOMEPAGE;
    }
    if(err?.response?.data?.code) {
      toast(err?.response?.data?.code)
    }

    return err.response;
  }

};
